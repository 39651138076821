import React, { useState, useEffect } from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import * as styles from '../../templates/menu.module.css'
import infoImg from '../../images/info.png'
import favYes from '../../images/fav_yes.svg'
import favNo from '../../images/fav_no.svg'

export default function DynamicWeek(props) {
  const currentDate = new Date();
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)

  const today = `${currentDate.getDate()}_${currentDate.getMonth() + 1}_`;
  const tomorrow = `${tomorrowDate.getDate()}_${tomorrowDate.getMonth() + 1}_`;

  var isCurrentWeek = true;

  if (typeof window !== 'undefined') {
    isCurrentWeek = !window.location.href.endsWith('_2') && !window.location.href.endsWith('_2/');
  }
  
  // Toggle element visibility
  const toggle = elemId => {
    document.getElementById(elemId).classList.toggle(styles.isVisible);
  };

  const [ownRestaurant, setOwnRestaurant] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const browser = typeof window !== 'undefined';

  useEffect(() => {
    try {
      if (browser) {
        async function fetchData() {
          // fetch data from DB
          try {
            let apiUrl = window.location.href.indexOf('localhost') > -1
              ? 'http://localhost:7071/api'
              : window.location.href.indexOf('azurestaticapps') > -1 ?
                'https://apim-ruoka.azure-api.net/kouluruoka-api' : '/api';

            let menu = await window.fetch(apiUrl + '/weekmenu?id=' + props.params.restaurantId);

            let res = await menu.json();

            setPageContent(res);

            let or = localStorage.getItem('ownRestaurant');
            setOwnRestaurant(or && or.split(';#').filter(o => o.split('|').includes('d_' + res.Restaurant.City.UrlName + '_' + res.Restaurant.UrlName)).length > 0);
          }
          catch (error) {
            console.log('Error fetching DB menus')
          }
        }

        fetchData();
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  // This runs when the page is loaded.
  useEffect(() => {
    try {
      if (browser) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
        adsbygoogle.push({});
      }
    } catch (e) {
      console.error(e);
    }

    if (isCurrentWeek) {
      let cdElemToday = document.getElementById(today);
      let cdElemTomorrow = document.getElementById(tomorrow);

      if (cdElemToday) {
        cdElemToday.classList.add(styles.now);
        cdElemToday.after(document.getElementById('adbanner'));
        cdElemToday.scrollIntoView();
      }

      if (cdElemTomorrow) {
        cdElemTomorrow.after(document.getElementById('adbanner2'));
      }
    } else {
      if (document.getElementsByClassName('dayitem_0') && document.getElementsByClassName('dayitem_0').length > 0) {
        document.getElementsByClassName('dayitem_0')[0].after(document.getElementById('adbanner'));
      }

      if (document.getElementsByClassName('dayitem_1') && document.getElementsByClassName('dayitem_1').length > 0) {
        document.getElementsByClassName('dayitem_1')[0].after(document.getElementById('adbanner2'));
      }
    }
  }, [pageContent])

  const ToggleOwnRestaurant = () => {
    let or = localStorage.getItem('ownRestaurant');

    let ownRestaurantName = pageContent.Restaurant.Name.toLowerCase().includes('koulut') ? `${pageContent.Restaurant.City.Name} - ${pageContent.Restaurant.Name}` : pageContent.Restaurant.Name;
    let restaurantId = 'd_' + pageContent.Restaurant.City.UrlName + '_' + pageContent.Restaurant.UrlName;
    if (!or || (or && !or.includes(restaurantId))) {
      addOwnRestaurant(restaurantId, ownRestaurantName, or);
    } else {
      removeOwnRestaurant(restaurantId, or);
    }
  }

  const addOwnRestaurant = (id, name, or) => {
    if (!or) {
      localStorage.setItem('ownRestaurant', `${id}|${name}`);
    } else {
      localStorage.setItem('ownRestaurant', `${or};#${id}|${name}`);
    }

    setOwnRestaurant(true);
  }

  const removeOwnRestaurant = (id, or) => {
    let rArr = or.split(';#').filter(e => !e.split('|').includes(`${id}`));
    localStorage.setItem('ownRestaurant', rArr.join(';#'));

    setOwnRestaurant(false);
  }

  const weekNavigator = (dir, ignore) => {
    if (ignore) return;
    window.location.href = `/dmenu/${pageContent.Restaurant.City.UrlName + '_' + pageContent.Restaurant.UrlName}${dir ? '_2' : ''}`
  }

  const WeekListTop = <div className={`${styles.weekListTop} ${styles.weekList}`}>
    <span className={styles.noPrint}>{isCurrentWeek ? 'Tämä' : 'Ensi'} viikko</span>
    <button className={`${isCurrentWeek ? styles.disabled : ''} ${styles.noPrint}`} onClick={() => weekNavigator(false, isCurrentWeek)}>&lt;&lt; Tämä viikko</button>
    <button className={`${!isCurrentWeek ? styles.disabled : ''} ${styles.noPrint}`} onClick={() => weekNavigator(true, !isCurrentWeek)}>Ensi viikko &gt;&gt;</button>
  </div>

  const WeekListBottom = <div className={`${styles.weekListBottom} ${styles.weekList} ${styles.noPrint}`}>
    <button className={isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(false, isCurrentWeek)}>&lt;&lt; Tämä viikko</button>
    <span></span>
    <button className={!isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(true, !isCurrentWeek)}>Ensi viikko &gt;&gt;</button>
  </div>

  const IsOwnRestaurant = <button onClick={ToggleOwnRestaurant} className={styles.noPrint}><img src={favYes} alt="Poista oma ravintola" /></button>
  const NotOwnRestaurant = <button onClick={ToggleOwnRestaurant} className={styles.noPrint}><img src={favNo} alt="Valitse omaksi ravintolaksi" /></button>

  if (pageContent && pageContent.Restaurant) {

    const pageTitle = pageContent.Restaurant.Name === 'Koulut' ? `${pageContent.Restaurant.City.Name} - ${pageContent.Restaurant.Name}` : pageContent.Restaurant.Name;

    return (
      <Layout>
        <div>
          <style>{`
          /* smaller navi on menu page */
          header a {
              height: 40px!important;
              font-size: 1.8rem!important;
              line-height: 40px!important;
          }
          
          header {
              height: 60px!important;
              padding: 10px!important;
          }
          
          #pageTitle {
              font-size: 2rem!important;
              border-bottom: solid 1px #10984B;
          }
          
          #pageTitle img {
              max-width: 32px;
          }
          
          .main {
              margin-top: 0;
          }
          `}
          </style>
          <SEO
            title={`${pageTitle} - Ruokalista ${isCurrentWeek ? 'tällä' : 'ensi'} viikolla`}
            description={`Aamupala, lounas, päivällinen tai välipala ${isCurrentWeek ? 'tällä' : 'ensi'} viikolla`}
          />

          <div className={styles.pageTitle}>
            <h1 id={'pageTitle'}>{pageTitle}
              {ownRestaurant
                ? IsOwnRestaurant
                : NotOwnRestaurant
              }
            </h1>
          </div>

          {WeekListTop}

          {pageContent.Days && pageContent.Days.length > 0 ? pageContent.Days.map((day, day_i) => (
            day.Meals && day.Meals.length > 0 ?
              <div id={day.Title.replace(/\./ig, '_').split(' ')[1]} key={day_i} className={`${styles.dayMenu} dayitem_${day_i}`}>
                <article>
                  <h2>{day.Title
                    .replace("Monday", "maanantai")
                    .replace("Tuesday", "tiistai")
                    .replace("Wednesday", "keskiviikko")
                    .replace("Thursday", "torstai")
                    .replace("Friday", "perjantai")
                    .replace("Saturday", "lauantai")
                    .replace("Sunday", "sunnuntai")}</h2>
                  {
                    day.Meals.map((meal, meal_i) => {
                      return (
                        <div key={meal_i}>
                          <h3>{meal.type}</h3>
                          <div>
                            <span dangerouslySetInnerHTML={{ __html: meal.Name.replace(/\*LBR\*/g, '<br/>') }}></span>
                            {
                              meal.Nutritions && meal.Nutritions.length > 0 ? <button onClick={() => toggle(`nb_${day_i}${meal_i}`)} className={styles.noPrint}><img src={infoImg} alt="Ravintotiedot" /></button> : ''
                            }
                          </div>
                          {
                            meal.Nutritions && meal.Nutritions.length > 0 ?
                              <div id={`nb_${day_i}${meal_i}`} className={`${styles.nutritions} ${styles.toggleContent}`}>
                                {
                                  meal.Nutritions.map((nutr, nutr_i) => {
                                    return (
                                      <div key={nutr_i}><h4>{nutr.Name}</h4>
                                        <h5>Ainesosat:</h5> <span dangerouslySetInnerHTML={{ __html: nutr.Label ? nutr.Label.replace(/\*SS\*/g, '<strong>').replace(/\*ES\*/g, '</strong>') : '' }}></span>
                                        {nutr.Items ?
                                          <><h5>Ravintosisältö (100 g:ssa)</h5>
                                            {nutr.Items.map((item, item_i) => (<span key={item_i}>{item.Text}<br /></span>))}
                                          </>
                                          : ''}
                                      </div>
                                    )
                                  })
                                }
                              </div>
                              : ''
                          }
                        </div>
                      )
                    })
                  }
                </article>
              </div>
              : ''
          )) :
            <div>
              Ei ruokalistaa tälle viikolle :(

              <div id="adbanner3" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner3">
                <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
                  data-ad-client="ca-pub-2668838512868610"
                  data-ad-slot="5745330552"
                  data-ad-format="horizontal,auto"
                  data-full-width-responsive="true"></ins>
              </div>
            </div>
          }

          {WeekListBottom}

          <div className={styles.abbreviations}>
          VL - Vähälaktoosinen, L - Laktoositon, M - Maidoton, G - Gluteeniton, K - Kasvis/maidoton/kananmunaton, S - Sianlihaton, N - Naudanlihaton, MU - Kananmunaton, VEG - Vegaani, */♥/SY - Sydänmerkki
          </div>

          <div id="adbanner" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner">
            <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
              data-ad-client="ca-pub-2668838512868610"
              data-ad-slot="5745330552"
              data-ad-format="horizontal,auto"
              data-full-width-responsive="true"></ins>
          </div>
          <div id="adbanner2" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner2">
            <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
              data-ad-client="ca-pub-2668838512868610"
              data-ad-slot="5745330552"
              data-ad-format="horizontal,auto"
              data-full-width-responsive="true"></ins>
          </div>
        </div>
      </Layout >
    )
  } else {
    return (<Layout><div>
      Haetaan ruokalistaa...
    </div>
    </Layout>
    )
  }
}